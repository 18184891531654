//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import constant from '~/const';

export default {

  props: {
    status: { type: Number, default: null },
  },
  data() {
    return {
      constant,
    };
  },
};
