//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      currentScheme: 0,
    };
  },

  computed: {
    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    schemeList() {
      return [
        { id: 0, title: this.$t('Crypto manual') },
        { id: 1, title: this.$t('Crypto auto') },
        { id: 2, title: this.$t('Fiat manual') },
        { id: 3, title: this.$t('Fiat auto') },
      ];
    },
  },
};
