//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import constant from '~/const';
import User from '~/components/User.vue';

export default {

  components: { User },
  props: {
    className: { type: String, default: '' },
    data: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      loading: false,
      modal: false,
      matchUsers: [],
    };
  },

  computed: {
    getWarningCaption() {
      const { WARNING_TYPE_LIST } = constant.users;
      const { warningType } = this.data;
      const warning = WARNING_TYPE_LIST.find((e) => e.id === warningType);
      if (warning) {
        return this.$t(`const.${warning.caption}`);
      }
      return '';
    },

    hasAdditionalInfo() {
      const { IP_ADDRESS, USER_AGENT, DOCUMENT_NUMBER } = constant.users.WARNING_TYPE;
      const { warningType } = this.data;
      return [IP_ADDRESS, USER_AGENT, DOCUMENT_NUMBER].includes(warningType);
    },
  },

  methods: {
    ...mapActions('common', ['setGeneralProgress']),
    ...mapActions('users', ['getUsersByParams']),

    async showMore() {
      this.loading = true;
      this.setGeneralProgress(true);

      const { IP_ADDRESS, USER_AGENT, DOCUMENT_NUMBER } = constant.users.WARNING_TYPE;
      const { details, warningType } = this.data;
      const payload = {
        ip: warningType === IP_ADDRESS ? details : null,
        userAgent: warningType === USER_AGENT ? details : null,
        documentNumber: warningType === DOCUMENT_NUMBER ? details : null,
      };

      const { items } = await this.getUsersByParams({
        ...payload,
        limit: 1000,
      });
      this.matchUsers = items;
      this.modal = true;

      this.loading = false;
      this.setGeneralProgress(false);
    },
  },
};
